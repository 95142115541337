import react from "react";
import Header from "./components/Header";

import HomeMb from "./components/mobilePages/HomeMb";

function App() {
  return (
    <>
       <Header titulo="daveloper" />
       <HomeMb/>
    </>
  );
}

export default App;

