import React from "react";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { useState } from "react";

const MySwal = withReactContent(Swal)





function Experiencia ()  {
  const [value, setValue] = useState('');
  const press = () => {
    MySwal.fire({
      title: <p>Hello World</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    }).then(() => {
      return MySwal.fire(<p>Shorthand works too</p>)
    })
  }

  
return(
  <div className="container">

    <form action="">
    <FloatLabel>
                <InputText id="username" name="user_name" value={value} onChange={(e) => setValue(e.target.value)} required/>
                <label for="username">Nombre</label>
            </FloatLabel>
    </form>

<button onClick={press}>PRECIONA</button>
    
  </div>
)
}

export default Experiencia;