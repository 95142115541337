import react from "react"
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function HeaderMb () {
  return(
<header className="w-100">
<div className=" cont-headermb w-100"><h2>Daveloper</h2></div>
</header>
  )
}

export default HeaderMb