import React from "react";
//import "react-use-smooth-scroll/dist/index.css";
import Footer from "./components/Footer";
import Presentacion from "./components/pages/Presentacion";

import astronauta2 from "./img/astronauta2.png";
import Proyectos from "./components/pages/Proyectos";

import Servicios from "./components/pages/servicios";
import AboutMe from "./components/pages/AboutMe";
import Experiencia from "./components/pages/Experiencia";
import HomeMb from "../src/components/mobilePages/HomeMb"




function Home() {
  return (
    <body className="body-home">
   
      <div className="container-home">
        <section className="two about-me" id="section1">
          <Presentacion />
        
        </section>
        <section className="two about-me" id="section2">
        <AboutMe />
     
        </section>
        <section className="two about-me"id="section3">
        <Servicios />
        </section>
        {/* <section className="about-me none-mb" id="section4">
        <Experiencia />
        </section> */}
        <section className="four" id="section5">
          <Footer />
   
        </section>
      </div>
    </body>
  );
}

export default Home;
