import react from "react";
import mail from "../img/social/Mail.png";
import LinkedIn from "../img/social/LinkedIn.png";
import GitHub from "../img/social/GitHub.png";
import Contact from "./Contact";
import "../style/css/footer.css";
import { motion } from "framer-motion";

function Footer() {
  return (
    <>
      <div className="foot row ">
        <div className="row row-text-footer text-center">
          <p className="text-footer">
            ¡Wow, has llegado al final de mi portafolio! ¡Eso es más que
            suficiente para ganar un premio! En serio, gracias por visitar.
            Espero que hayas disfrutado viendo mi trabajo y que hayas encontrado
            algo que te haya hecho sonreír.
          </p>
        </div>

        <div className="row row-social">
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="col"
          >
            <a href="https:www.linkedin.com/in/dave-esteban-hyacinthe-b533451a8">
              <img src={LinkedIn} alt="Icono de Linkedin Footer" />
            </a>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="col"
          >
            <a href="https://github.com/DaveHya">
              <img src={GitHub} alt="Icono de Linkedin Footer" />
            </a>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="col"
          >
            <a href="/contact">
              <img src={mail} alt="Icono de Linkedin Footer" />
            </a>
          </motion.div>
        </div>
        <div className="row contenedor-footer">
          <h3 className="rights-footer">Desarrollado por mi!</h3>
        </div>
      </div>
    </>
  );
}

export default Footer;

/* 

<div className="col">
         
        <a href="https://www.instagram.com/estebass7m/" target="_blank"><img src={Instagram}  width="70%" className="insta" alt="" /></a>
        </div>
        <div className="col">
        <a href="https://www.linkedin.com/in/dave-hyacinthe-b533451a8/" target="_blank"><img src={LinkedIn}  width="70%" className="linke" alt="" /></a>
        </div>
        <div className="col">
        
          <a href="https://github.com/DaveHya" target="_blank"><img src={GitHub}  width="100%" className="gith" alt="" /></a>
        </div>
*/

{
  /* <footer className="container-footer ">
<div className="row col-text">
  <h2 className="title title-hello">Social Network </h2>

  <p className="text-about "></p>
</div>

<div className="row cont-list">
<ul className="list">

<li><a href="#"><i class="fa fa-envelope" aria-hidden="true"></i></a></li>
<li><a href="https://www.linkedin.com/in/raj-kumar-web-designer/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
<li><a href="#"><i class="fa fa-github" aria-hidden="true"></i></a></li>
</ul>
</div>

<div className=" row cont-social">
  <p className=""> Copyright © 2024 Daveloper </p>
</div>
</footer> */
}
