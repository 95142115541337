import React from "react";
import "bootstrap";
import { motion } from "framer-motion";
//Import Imagenes
import "bootstrap/dist/css/bootstrap.min.css";
import imagePre from "../../img/imagePre.png";
// import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ScrollTop } from 'primereact/scrolltop';
import PresentacionMb from "../mobilePages/PresentacionMb";
import "../../style/css/responsive.css"

        

function Presentacion() {

  return (
   
    <div className="row container-pre" width="90%">

<PresentacionMb/>
    <div className="row-1 row none-mb">
      {/*Columna izquierda */}
      <div  className="col col-name">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 2, type: "spring", stiffness: 40 }}
        className=" left-pre">
        <div className="container-title-hello">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
            className="title title-hello container-70%"
          >
            Dave Hyacinthe{" "}
          </motion.h1>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
            class="content row slider-pre"
          >
            <div class="content__container">
              <p class="content__container__text">Soy</p>
              <ul class="content__container__list">
                <li class="content__container__list__item">
                  Developer{" "}
                </li>
                <li class="content__container__list__item">Frontend</li>
                <li class="content__container__list__item">Creativo</li>
                <li class="content__container__list__item">Innovador</li>
              </ul>
            </div>
          </motion.div>

          <div className="container-text-p row">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 3 }}
              className="text-p headline"
            >
              ¡Hola! Los invito a explorar mi portafolio web, donde podrán descubrir mis habilidades en la <span className="bg-secondary opacity-60 text-white p-1 rounded-2">Programación</span>, conocer más sobre mí y ver los <span className="bg-secondary opacity-60 text-white p-1 rounded-2">Servicios</span> que ofrezco.   
            </motion.p>
          </div>
        </div>
      </motion.div>

      {/*Fila de redes sociales*/}
      <motion.div  initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 10, type: "spring", stiffness: 40 }} className="row cont-list">
      <ul className="list">
  
  <motion.li initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 7, type: "spring", stiffness: 35 }}><a  href="/contact"><i class="fa fa-envelope" aria-hidden="true"></i></a></motion.li>
  <motion.li initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 8, type: "spring", stiffness: 25 }}><a target="_blank"  href="https://www.linkedin.com/in/dave-esteban-hyacinthe-b533451a8"><i class="fa fa-linkedin" aria-hidden="true"></i></a></motion.li>
  <motion.li initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 9, type: "spring", stiffness: 15 }}><a target="_blank" href="https://github.com/DaveHya"><i class="fa fa-github" aria-hidden="true"></i></a></motion.li>
</ul>
      </motion.div>
      </div>

      {/*Columna derecha */}
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 5, type: "spring", stiffness: 20 }}
        className="col col-image none-mb"
       
      >
        <img
          src={imagePre}
          className="img-pre"
          height="auto"
          width="auto"
          alt="Avatar Dave"
        ></img>
      </motion.div>

     {/*toast*/}
    
    
      </div>

      
    </div>
  );
}

export default Presentacion;

/*<img
src={daveIcon}
// className="astronauta"
height="250px"
width="480px"
alt="Avatar Dave"
></img> */
