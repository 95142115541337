import react from 'react';
import { Mailer } from './Mail/Mailer';



function Contact() {
    return ( 
        <div className="container-contact">
        <Mailer/>
        </div>
     );
}

export default Contact;