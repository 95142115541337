import React,{useEffect} from "react";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import services from "../../img/services.png";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../style/css/bootstrap.css";
import { BubblyContainer, BubblyLink } from "react-bubbly-transitions";





function Servicios() {
  //Funcion para aumentar el numero 
//   const count = useMotionValue(0)
// const rounded = useTransform(count, latest => Math.round(latest))


// useEffect(() => {
//   const animation = animate(count, 15, { duration: 15 });

//   return animation.stop;
// }, []);

  return (
    
    <div className="container-servicios">
      <div className="row text-center pt-4  mt-5 m-0"> <h1 className="title m-0">¿Tienes algún proyecto? </h1></div>
    <div className="row row-mb">
      
      <div className="col col-text">
       

        <h2 className=" text-center text-dark  font-weight-normal  mb-4 ">
          Estos son mis servicios
        </h2>

        <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      🚀 Creación de Pagina web / Sitio web
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Trabajo en estrecha colaboración contigo para entender la esencia única de tu marca y traducir esa identidad en un diseño web cautivador y auténtico.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      🛍️ Creación de tiendas virtuales (Ecommerce)
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"> Creación de tiendas virtuales adaptadas a la identidad única de tu marca. Cada detalle de diseño se orienta hacia la creación de una experiencia de compra atractiva y coherente con tu imagen corporativa.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      👨🏾‍💻 Colaboración en proyectos / Freelancer
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"> Ofrezco servicios de colaboración en proyectos informáticos web, diseñados para potenciar las iniciativas digitales de mis clientes y garantizar resultados exitosos.</div>
    </div>
  </div>
</div>
        
      </div>

      <div className="col col-img-servicios none-mb">
          <img src={services} height="auto" width="70%" ></img>
         
          
       
        </div>
        {/* <motion.div className="rounded">{rounded}</motion.div> */}
        <div className="row"><a className="text-center" ><BubblyLink
                        colorStart="#364959"
                        colorEnd="#eae0c2"
                        className="btn-header"
                        to="/contact"
                      >
                      <motion.button whileHover={{ scale: 1.1 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }} className="btn-g"> Contactame</motion.button>
                      </BubblyLink>{" "}</a></div>
    </div>
  

  </div>
  );
}

export default Servicios;

