import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Edelbrock from "../../img/Edelbrock.png";
import casasilva from "../../img/casa-silva.gif";
import caffarena from "../../img/caffarena.png";
import ferouch from "../../img/ferouch.png";
import sally from "../../img/sally-beauty.jpg";
import nimtu from "../../img/nimtu.jpg";
import midea from "../../img/midea.png";
import kayser from "../../img/kayser.png";
import liquimoly from "../../img/liquimoly.png";
import directwines from "../../img/directwines.png";
import markup from "../../img/markup.png";
import safetyStore from "../../img/safetyStore.png";
import propulsow from "../../img/propulsow.jpg";
import casaAmarilla from "../../img/casaAmarilla.png";
import angloAmerican from "../../img/angloAmerican.png"
import fiorentina from "../../img/fiorentina.png"
import Col from 'react-bootstrap/Col';
import ProyectosMb from "../mobilePages/ProyectosMb";
import nike from "../../img/nike.jpg";

import { motion } from "framer-motion";


function Proyectos() {
  return (
    <div className="container-proyectos">
      <div className="row row-proyecto ">
        <div className="row d-flex justify-content-center  align-items-center">
          <h2 className=" d-flex justify-content-center title mb-4">Proyectos </h2>

          {/* <p className=" text-proyecto d-flex justify-content-center text-about">
          He tenido la oportunidad de trabajar en una variedad de proyectos destacados, que incluyen tiendas en línea B2B y B2C, y otras soluciones innovadoras. Estos son los proyectos:
          </p> */}
        </div>

{/*Fila 1*/}
      <motion.div className="row  pro-row-1 none-mb">
        <div className="col  col-proyectos"><a href="https://www.mideastore.cl/" target="_blank"><img title="Midea Store" className="img-proyecto" src={midea} width="90%" height="auto"></img></a></div>
        <div className="col  col-proyectos"><a href="https://www.sallybeauty.cl/?gad_source=1&gclid=CjwKCAjwte-vBhBFEiwAQSv_xbRx9aEbt9Wa3sSPq_EwAiM4Vagdblfh6y2gcLcP7gsF89LW6t4mPRoCCqkQAvD_BwE" target="_blank"><img title="Sally Beauty" className="img-proyecto" src={sally} width="90%" height="auto"></img></a></div>
        <div className="col  col-proyectos"><a href="https://www.consultorasvdc.cl" target="_blank"><img title="Caffarena" className="img-proyecto" src={caffarena}  width="80%" height="auto"></img> </a></div>
        <div className="col col-proyectos"><a href="https://www.kaysershop.com/?gad_source=1&gclid=CjwKCAjwte-vBhBFEiwAQSv_xfp9oK21TpJ7U4UEDTBhp4BOBDC18xPkhKs50u8lEXqXLns2pOJeGxoCD7sQAvD_BwE" target="_blank"><img title="Kayser" className="img-proyecto"src={kayser}  width="75%" height="auto"></img></a></div>
    
        <div className="col  col-proyectos"><a href="https://www.liqui-moly.cl/" target="_blank"><img title="Liquimoly" className="img-proyecto" src={liquimoly}  width="90%" height="auto"></img></a></div>
      </motion.div>

{/*Fila 2*/}
      <div className="row pro-row pro-row-1 mb-6 none-mb">
        <div className="col col-proyectos"><a href="https://www.anglo.b2bweb.cl/" target="_blank"><img className="img-proyecto" src={angloAmerican} width="100%" height="100%"></img></a></div>
        <div className="col  col-proyectos"><a href="https://www.casasilva.cl/" target="_blank"><img title="Casa Silvas" className="img-proyecto" src={casasilva} width="90%" height="70%"></img></a></div>
       
        <div className="col  col-proyectos"><a href="https://www.casamarilla.cl/" target="_blank"><img className="img-proyecto" src={casaAmarilla}  width="85%" height="70%"></img></a></div>
        <div className="col  col-proyectos"><a href="https://www.safetystore.cl/" target="_blank"><img className="img-proyecto"src={safetyStore}  width="65%" height="60%"></img></a></div>
        <div  className="col col-proyectos"><a href="https://www.fiorentina.cl/" target="_blank"><img className="img-proyecto" src={fiorentina} width="70%" height="90%"></img></a></div>
      </div>
      
{/*Fila 3*/}
      <div className="row pro-row pro-row-1 none-mb">
        <div className="col  col-proyectos"> <a href="https://www.edelbrock.cl/?gad_source=1&gclid=CjwKCAjwte-vBhBFEiwAQSv_xSpVKkqu9_EA9lZWqhL8tZ_WbwTZ6jRjQTene_ah0D5A-ZUDNG33txoCR-YQAvD_BwE" target="_blank"><img title="Edelbrock" className="img-proyecto" src={Edelbrock} width="100%" height="100%"></img></a></div>
     

        <div className="col  col-proyectos"><a href="https://www.nimtu.cl/" target="_blank"><img title="Nimtu" className="img-proyecto" src={nimtu} width="65%" height="80%"></img></a></div>

       
        <div className="col  col-proyectos"><a href="https://www.directwines.cl/" target="_blank"><img title="Direct Wines" className="img-proyecto"src={directwines}  width="70%" height="60%"></img></a></div>
        <div className="col  col-proyectos"><a href="https://www.markup.cl/" target="_blank"><img title="Markup" className="img-proyecto" src={markup} width="85%" height="90%"></img></a></div>

        <div className="col  col-proyectos"><a href="https://www.propulsow.com/" target="_blank"><img className="img-proyecto" src={propulsow} width="65%" height="60%"></img></a></div>
      </div>

      {/*Fila 4*/}
    <  div className="row pro-row pro-row-1 none-mb">
   <h2 className=" h2 mb-4">Pagina en construcción 🟢</h2>
   <p className=" h4 dark-text mb-4">Replica de Nike (Wordpress + Elementor)</p>
        <div className="col mb-3  justify-content-center  col-proyectos"> <a href="http://siesspa.store/" target="_blank"><img title="Edelbrock" className="img-proyecto" src={nike} width="20%" height="auto"></img></a></div>

        
      </div>
      </div>
     
      <ProyectosMb/>
    </div>
  );
}

export default Proyectos;

