import React from "react";
import emailjs from "@emailjs/browser";

export const MailerMb = () => {

    const sendEmail = (event) => {
      event.preventDefault();
  
      emailjs
        .sendForm(
          "service_itbg53s",
          "template_ntat1kg",
          event.target,
          "Kr_eVLVPezO34MYsV"
        )
        .then((response) => console.log(response) )
        .catch((error) => console.log(error));
     
     
    };
  



    
    return(
        <div className="div-form none-dk">
        <div className="col row-contacto ">
            <h2 className="title">Contactame! </h2>
  
          
          </div>
        <form className="form-mail row " onSubmit={sendEmail}>
        <div className="row">
        <div className="row">
        <label className="label-contact">Nombre</label>
        <input type="text" name="user_name" required />
        </div>

        <div className="row">
        <label className="label-contact contact-mensaje">Apellidos</label>
        <input type="text" name="user_lastname" required />
        </div>
        </div>
      
       <div className="row">
       <div className="row">
        <label className="label-contact contact-mensaje">Teléfono</label>
        <input type="text" name="user_number" required />
        </div>

        <div className="row">
        <label className="label-contact contact-mensaje">Email</label>
        <input className="input-email" type="email" name="user_email" required />

        </div>
       </div>
       

        <label  className="contact-mensaje ">Mensaje</label>
        <textarea
          name="user_message"
          id=""
          cols="30"
          rows="10"
          required
        ></textarea>

        <div className="row container-submit">
        <button className="btn-submit" > Enviar</button>
       
        </div>

      
      </form>
        </div>
    )
}

export default MailerMb;