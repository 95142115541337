import React from "react";
import "../../style/css/appMb.css"
import HeaderMb from "./HeaderMb";
import mail from "../../img/social/Mail.png";
import LinkedIn from "../../img/social/LinkedIn.png";
import GitHub from "../../img/social/GitHub.png";

import { motion } from "framer-motion";


function HomeMb() {
  return (
    <body className="homeMb ">
        <HeaderMb/>
      <div className=" content-cortina align-self-center w-80">
        <h2 className="d-flex align-items-end justify-content-center mb-2">Bienvenidos a mi </h2>
        <h1 className="text-center mb-4 titleMb text-white ">Portafolio Mobile</h1>
        <p className="d-flex align-items-start text-center font-weight-bold mb-5 text-cortina">Estamos en construcción Mobile. Mientras tanto, visita la versión desktop para explorar mis proyectos y conocer mi trabajo.</p>
    

      <div className=" row-social-mb">
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className=""
          >
            <a target="_blank" href="https://www.linkedin.com/in/dave-esteban-hyacinthe-b533451a8">
              <img src={LinkedIn} width="80%" alt="Icono de Linkedin Footer" />
            </a>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className=""
          >
            <a target="_blank" href="https://github.com/DaveHya">
              <img src={GitHub} width="80%" alt="Icono de Linkedin Footer" />
            </a>
          </motion.div>
          {/* <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="col"
          >
            <a href="/contact">
              <img src={mail} alt="Icono de Linkedin Footer" />
            </a>
          </motion.div> */}
        </div>
        </div>
    </body>
  );
}

export default HomeMb;
