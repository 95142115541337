import React from "react";
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import { BubblyContainer, BubblyLink } from "react-bubbly-transitions";
import Home from "../Home";
import "../style/css/app.css";
import "../style/css/appMobile.css";
import Contact from "./Contact";
import logo from "../img/logo.png";
import Proyectos from "./pages/Proyectos";
import Certificaciones from "./pages/Certificaciones";
import { motion } from "framer-motion";

//const Home = () => <div>Home</div>;
const About = () => <div>About</div>;
//const Contact = () => <div>Contact</div>;

function Header({ titulo }) {
  return (
    <header className="header">
      <BrowserRouter>
        <BubblyContainer />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <motion.div  initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }} transition={{duration:1}} className="navbar-header">
                  <div className="left-header">
                    {" "}
                    <a className="">
                      {" "}
                      <BubblyLink
                        className="btn-header"
                        colorStart="#364959"
                        colorEnd="#D9D9D9"
                        to="/"
                      >
                        <img className="logoMobile" src={logo} height="130px" href="#"></img>{" "}
                      </BubblyLink>{" "}
                    </a>{" "}
                  </div>

                  <div className="right-header">
                    <ul className="ul-header">
                      <motion.li  whileHover={{  x:-5 }} whileTap={{scale:1.1}} > <a   className="btn-header">
                      <BubblyLink
                        colorStart="#364959"
                        colorEnd="#D9D9D9"
                     
                        to="/proyectos"
                      >
                        Proyectos
                      </BubblyLink>{" "}
                    </a></motion.li>

                      <motion.li whileHover={{  x:5 }} whileTap={{scale:1.1}}> <a  className="btn-header">
                      {" "}
                      <BubblyLink
                        colorStart="#364959"
                        colorEnd="#D9D9D9"
                       
                        to="/contact"
                      >
                        Contacto
                      </BubblyLink>
                    </a></motion.li>
                    </ul>
                    
                  
                   
                  </div>
                </motion.div>
                <Outlet />
              </>
            }
          >
            <Route index element={<Home />} />
            <Route path="Proyectos" element={<Proyectos />} />
            <Route path="Certificaciones" element={<Certificaciones />} />
            <Route path="contact" element={<Contact />} />

            <Route path="*" element={<>No Match</>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </header>
  );
}

export default Header;
