import React from "react";
import { motion } from "framer-motion";
import daveseat from "../../img/daveseat.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { BubblyContainer, BubblyLink } from "react-bubbly-transitions";
import vtex from "../../img/skills/vtex.png";
import Git from "../../img/skills/Git.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'
import Figma from "../../img/skills/Figma.png";
import js from "../../img/skills/JS.png";
import sass from "../../img/skills/Sass.png";
import css from "../../img/skills/CSS.png";
import html from "../../img/skills/Html.png";
import wordpress from "../../img/skills/wordpress.png";
import boostrap from "../../img/skills/boostrap.png";
import react from "../../img/skills/react.png";
import github from "../../img/skills/GitHub.png"
import Marquee from "react-fast-marquee";
import midea from "../../img/midea.png"
import caffarena from "../../img/caffarena.png"
import liquimoly from "../../img/liquimoly.png"
import kayser from "../../img/kayser.png"


function AboutMe() {
  return (
    <div className="container-general">
      <div className="row g-8 row-about">
        <div className="col col-text bent text-center col-6">
          <h2 className="subtitle">Sobre Mi </h2>

          <p className="text-about text-center descri-me none-mb w-70">
           Soy Ingeniero Informático especializado en Frontend y Ecommerce con 2.5 años de experiencia. Creo interfaces de usuario atractivas y funcionales.
          </p>
        </div>

        <div className="col col-text bent text-center col-5  gap-3">
          <h2 className="subtitle">Skill Tech </h2>

          <Marquee speed={30} pauseOnHover={true}>
            <img src={react} width="auto" height="auto" alt="Icon React"></img>
          <img src={Git} width="auto" height="auto" alt=""></img>
          <img src={js} width="auto" height="auto" alt=""></img>
          <img src={Figma} width="auto" height="auto" alt=""></img>
          <img src={vtex} width="auto" height="auto" alt=""></img>
          <img src={sass} width="auto" height="auto" alt=""></img>
          <img src={html} width="auto" height="auto" alt=""></img>
          <img src={css} width="auto" height="auto" alt=""></img>
          <img src={wordpress} width="auto" height="auto" alt=""></img>
          <img src={boostrap} width="auto" height="auto" alt=""></img>
          <img src={github}  width="auto" height="auto" alt=""></img>
        </Marquee>
        </div>
       
      </div>

      {/*Barra Inferior*/}
      <div className="row g-6 row-about none-mb">
        
      <div className="col col-text bent text-center col-5">
          <h2 className="subtitle"> Proyectos </h2>
          <div className="cont-proyectos  ">
          <img src={kayser} className="img-proyecto" width="17%" height="auto" alt=""></img>
          <img src={midea} className="img-proyecto" width="20%" height="auto" alt=""></img>
          <img src={caffarena} className="img-proyecto" width="17%" height="auto" alt=""></img>
          <img src={liquimoly} className="img-proyecto" width="20%" height="auto" alt=""></img>
          </div>
          <div className="row"><a className="text-center" ><BubblyLink
                        colorStart="#364959"
                        colorEnd="#eae0c2"
                        className="btn-header"
                        to="/proyectos"
                      >
                      <motion.button whileHover={{ scale: 1.1 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }} className="btn-g"> Ver Proyectos</motion.button>
                      </BubblyLink>{" "}</a></div>
        </div>
      <div className="col col-text bent text-center col-6">
          <h2 className="subtitle">Experiencia </h2>

          <p className="text-about text-center text-pw">
            <strong className=""> PROPULSOW</strong> (FrontEnd) 🔴
          </p>

          <p className="text-about text-center text-pw">
            <strong className=""> CALIFORNIA SA</strong> (Ecommerce Developer) 🟢 
          </p>

      
       
        </div>

       
      </div>
    </div>
  );
}
export default AboutMe;


