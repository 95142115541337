import React from "react";
import "bootstrap";
import { motion } from "framer-motion";
//Import Imagenes
import "bootstrap/dist/css/bootstrap.min.css";

// import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ScrollTop } from 'primereact/scrolltop';

        
      

function PresentacionMb() {

  return (
   
    <div className=" container-pre none-dk" >

    <div className="row-1 ">
      {/*Columna izquierda */}
      
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 2, type: "spring", stiffness: 40 }}
        className=" left-pre"
      
      >
        <div className="container-title-hello">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
            className="title title-hello container-70%"
          >
            Dave Hyacinthe {" "}
          </motion.h1>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
            class="content row slider-pre"
          >
            <div class="content__container">
              <p class="content__container__text">Soy</p>
              <ul class="content__container__list">
                <li class="content__container__list__item">
                  Ing. Informatico{" "}
                </li>
                <li class="content__container__list__item">Frontend</li>
                <li class="content__container__list__item">Creativo</li>
                <li class="content__container__list__item">Desarrollador</li>
              </ul>
            </div>
          </motion.div>

          <div className="container-text-p ">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 3 }}
              className="text-p headline"
            >
              Hola! Los invito a explorar mi portafolio web, donde podrán descubrir mis habilidades en la <strong>programación</strong>, conocer más sobre mí y ver los servicios que ofrezco. Espero que disfruten navegando por mi trabajo y encuentren algo que les inspire!  <ScrollTop/>
            </motion.p>
          </div>
        </div>
      </motion.div>
      </div>

  

    </div>
  );
}

export default PresentacionMb;

/*<img
src={daveIcon}
// className="astronauta"
height="250px"
width="480px"
alt="Avatar Dave"
></img> */
