import React from "react";
import Edelbrock from "../../img/Edelbrock.png";
import casasilva from "../../img/casa-silva.gif";
import caffarena from "../../img/caffarena.png";
import ferouch from "../../img/ferouch.png";
import sally from "../../img/sally-beauty.jpg";
import nimtu from "../../img/nimtu.jpg";
import midea from "../../img/midea.png";
import kayser from "../../img/kayser.png";
import liquimoly from "../../img/liquimoly.png";
import directwines from "../../img/directwines.png";
import markup from "../../img/markup.png";
import safetyStore from "../../img/safetyStore.png";
import propulsow from "../../img/propulsow.jpg";
import casaAmarilla from "../../img/casaAmarilla.jpg";
import angloAmerican from "../../img/angloAmerican.png"
import fiorentina from "../../img/fiorentina.png"

function ProyectosMb() {
  return(
    <>
    <div className="row pro-row-1 none-dk">
    <div className="col col-skill col-proyectos"><a href="https://www.mideastore.cl/" target="_blank"><img title="Midea Store" className="" src={midea} width="85%" height="85%"></img></a></div>
    <div className="col col-skill col-proyectos"><a href="https://www.sallybeauty.cl/?gad_source=1&gclid=CjwKCAjwte-vBhBFEiwAQSv_xbRx9aEbt9Wa3sSPq_EwAiM4Vagdblfh6y2gcLcP7gsF89LW6t4mPRoCCqkQAvD_BwE" target="_blank"><img title="Sally Beauty" className="" src={sally} width="90%" height="80%"></img></a></div>
    
    
  </div>

  <div className="row  none-dk">
   
    <div className="col col-skill col-proyectos"><a href="https://www.consultorasvdc.cl/caffarena?page=4" target="_blank"><img title="Caffarena" className="" src={caffarena}  width="70%" height="40%"></img> </a></div>
    <div className="col col-skill col-proyectos"><a href="https://www.kaysershop.com/?gad_source=1&gclid=CjwKCAjwte-vBhBFEiwAQSv_xfp9oK21TpJ7U4UEDTBhp4BOBDC18xPkhKs50u8lEXqXLns2pOJeGxoCD7sQAvD_BwE" target="_blank"><img title="Kayser" className=""src={kayser}  width="70%" height="70%"></img></a></div>
   
  </div>

  <div className="row  none-dk">
  <div className="col col-skill col-proyectos"> <a href="https://www.edelbrock.cl/?gad_source=1&gclid=CjwKCAjwte-vBhBFEiwAQSv_xSpVKkqu9_EA9lZWqhL8tZ_WbwTZ6jRjQTene_ah0D5A-ZUDNG33txoCR-YQAvD_BwE" target="_blank"><img title="Edelbrock" className="" src={Edelbrock} width="100%" height="100%"></img></a></div>
  <div className="col col-skill col-proyectos"><a href="https://www.nimtu.cl/" target="_blank"><img title="Nimtu" className="" src={nimtu} width="70%" height="80%"></img></a></div>
 </div>

<div className="row   none-dk">
<div className="col col-skill col-proyectos"><a href="https://www.casamarilla.cl/" target="_blank"><img className="" src={casaAmarilla}  width="85%" height="70%"></img></a></div>

<div className="col col-skill col-proyectos"><a href="https://www.liqui-moly.cl/" target="_blank"><img title="Liquimoly" className="" src={liquimoly}  width="70%" height="70%"></img></a></div>

</div>

<div className="row   none-dk">
<div className="col col-skill col-proyectos"><a href="https://www.directwines.cl/" target="_blank"><img title="Direct Wines" className=""src={directwines}  width="60%" height="40%"></img></a></div>

<div className="col col-skill col-proyectos"><a href="https://www.safetystore.cl/" target="_blank"><img className=""src={safetyStore}  width="65%" height="60%"></img></a></div>
</div>

<div className="row  none-dk">
        
<div className="col col-skill col-proyectos"><a href="\https://www.casasilva.cl/" target="_blank"><img title="Casa Silvas" className="" src={casasilva} width="85%" height="60%"></img></a></div>
<div className="col col-skill col-proyectos"><a href="https://www.markup.cl/" target="_blank"><img title="Markup" className="" src={markup} width="85%" height="90%"></img></a></div>
      </div>

      <div className="row  none-dk">
        <div className="col col-skill col-proyectos"><a href="https://www.anglo.b2bweb.cl/" target="_blank"><img className="" src={angloAmerican} width="100%" height="100%"></img></a></div>
        <div className="col col-skill col-proyectos"><a href="https://www.propulsow.com/" target="_blank"><img className="" src={propulsow} width="65%" height="60%"></img></a></div>
        <div  className="col col-skill col-proyectos"><a href="https://www.fiorentina.cl/" target="_blank"><img className="img-proyecto" src={fiorentina} width="70%" height="90%"></img></a></div>
     
       
      </div>


</>

  
  );
}

export default ProyectosMb;
