import React from "react";
import emailjs from "@emailjs/browser";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MailerMb from "./MailerMb";


        

export const Mailer = () => {
  const service = "service_238b4j2";
  const template = "template_oxyae2k";
  const keyJs = "9X_gPWABFh6peDi-g";

  const sendEmail = (event, status) => {
    event.preventDefault();

    emailjs
      .sendForm(service, template, event.target, keyJs)
      .then((response) => {
        const verify = () => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Tu mensaje se ha enviado bien!",
            showConfirmButton: false,
            timer: 1500,
          });
        };

        const wrong = () => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No se pudo enviar su petición!",
            footer: '<a href="#">Hubo un error con su petición</a>',
          });
        };

        // const handleChange = (e) => {
        //   const { name, value } = e.target;
        //   setFormData({
        //     ...formData,
        //     [name]: value,
        //   });
        // };

        if (!response) {
          wrong();
        } else {
          verify();
         
        }
      })

    
      .then((response) => console.log(response))
      .catch((error) => {
        const off = "No se envio el correo";
        console.log(error + off);
      })
     
      
  };

 

  return (
    <div className="div-form ">
      <div className="col row-contacto none-mb">
        <h2 className="title">Contactame! </h2>
      </div>

      <form className="form-mail row none-mb" onSubmit={sendEmail}>
        <div className="row">
          <div className="col">
            <label>Nombre</label>
            <input id="inputForm" type="text" name="user_name" required />
           
           
           
          </div>

          <div className="col">
            <label>Apellidos</label>
            <input id="inputForm" type="text" name="user_lastname" required />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>Teléfono</label>
            <input  id="inputForm" type="text" name="user_number" required />
          </div>

          <div className="col">
            <label>Email</label>
            <input
              id="inputForm"
              className="input-email"
              type="email"
              name="user_email"
              required
            />
          </div>
        </div>

        <label className="contact-mensaje">Mensaje</label>
        <textarea
          name="user_message"
          id="inputForm"
          cols="30"
          rows="10"
          required
        ></textarea>

        <div className="row container-submit">
          <button className="btn-submit"> Enviar</button>
        </div>
      </form>
      <MailerMb />
    </div>
  );
};

//onClick={verify}

// <label>{ValidacionCorreo()} Hola</label>

// <button className="btn-submit" onClick={ValidacionCorreo()}> Enviar</button>
