import React from "react";

import training1 from "../../img/training1.png";
import training2 from "../../img/vtex-training-2.png";
import training3 from "../../img/vtex-training-3.jpg";

import Col from 'react-bootstrap/Col';


function Certificaciones() {
  return (
    <div className="container-proyectos">
      <div className="row row-skills">
        <div className="row col-text">
          <h2 className="title">Certificaciones </h2>

          <p className="text-about">
           Mis Certificaciones y logros
          </p>
        </div>

      <div className="row pro-row-1 none-mb">
        <div className="col col-skill col-proyectos"><a ></a>
        <div class="modal-dialog modal-lg"><img title="Midea Store" className="" src={training1} width="90%" height="90%"></img></div>
        </div>
        <div className="col col-skill col-proyectos"><img title="Sally Beauty" className="" src={training2} width="90%" height="80%"></img></div>
        <div className="col col-skill col-proyectos"><img title="Caffarena" className="" src={training3}  width="100%" height="100%"></img></div>
        
      
      </div>

    

  
      </div>
      
  
    </div>
  );
}
export default Certificaciones;

